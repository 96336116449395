import '@/assets/scss/functions.css';
import '@/assets/scss/style.scss';
import '@/lang/i18n';
import '@/assets/newScss/main-style.scss';
import React from 'react';
import { render, hydrate } from 'react-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { RecoilRoot } from 'recoil';
import reportWebVitals from './reportWebVitals';
import version from './version.json';

console.log(
  `%c APP : ${version.app}, SERVER : ${version.server}, Release : ${version.release}`,
  'background: #F5D042; color: #0A174E'
);

TopBarProgress.config({
  barColors: {
    '0': '#f99',
    '1.0': '#fff',
  },
  shadowBlur: 1,
  barThickness: 1,
});

const App = React.lazy(() => import('./App'));

const rootElement = document.getElementById('root');

if (rootElement?.hasChildNodes()) {
  hydrate(
    <RecoilRoot>
      <React.Suspense fallback={<TopBarProgress />}>
        <App />
      </React.Suspense>
    </RecoilRoot>,
    rootElement
  );
} else {
  render(
    <RecoilRoot>
      <React.Suspense fallback={<TopBarProgress />}>
        <App />
      </React.Suspense>
    </RecoilRoot>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
